import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import InformationLayout from "../../layouts/InformationLayout.jsx";
import indexData from "./index.json";
export const _frontmatter = {
  "title": "Arbour Assembly - Attaching the Cross Rafters"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children,
  pagesList
}) => <InformationLayout pagesList={indexData.pageIndex}>
    {children}
  </InformationLayout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Step 4. Attaching the Cross Rafters`}</h1>
    <p>{`It is important that you allow equal spacing between the rafters as this will ensure that the final structure is aligned perfectly. Remember that the rafters over the posts are designed to fit centrally and it is easiest to fit these first and then fill in the gaps with the other cross rafters.`}</p>
    <p>{`Lay the rafter(s) on top of the main beam, ensuring that they are at right angles and fix to the main beams using the right-angle brackets and screws provided. This keeps the metalwork on the top side of the beam and out of sight. As Fig 19 demonstrates below:`}</p>
    <div className="captioned">
  <img src="/img/arbour-assembly/step-4-attaching-the-cross-rafters/n-fig21.jpg" alt="A diagram displaying the attachment of the rafters to the main beam, attached at 680mm intervals using angle brackets and the wood screws provided with the pergola" />
  <span className="text-metadata">Fig 19</span>
    </div>
    <p>{`Fig 20, 21 & 22 below show that the rafters fix centrally over the tops of the post and give further structural support when screwed in place.`}</p>
    <div className="captioned">
  <img src="/img/arbour-assembly/step-4-attaching-the-cross-rafters/arbour-fig-19.jpg" alt="A diagram displaying a side view of how to attach the rafters to the main beam, attached at 680mm intervals, beginning 254mm from the end of the main beam using angle brackets and the wood screws provided with the pergola" />
  <span className="text-metadata">Fig 20</span>
    </div>
    <div className="captioned">
  <img src="/img/arbour-assembly/step-4-attaching-the-cross-rafters/arbour-fig-20.jpg" alt="A diagram displaying where to attach the angle brackets mentioned in fig 19 and 20 for attaching the rafters to the main beam" />
  <span className="text-metadata">Fig 21</span>
    </div>
    <div className="captioned">
  <img src="/img/arbour-assembly/step-4-attaching-the-cross-rafters/n-fig22.jpg" alt="A diagram displaying a side view of the process described in fig 21" />
  <span className="text-metadata">Fig 22</span>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      